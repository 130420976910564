
* {
  box-sizing: border-box;
  text-align: right;
}

body {
 
  
 
  font-family: sans-serif;
  background: gray;  /* fallback for old browsers */
 
  background-image: linear-gradient(90deg, #020024 0%, #090979 35%, #00d4ff 100%);
}

body, html, .App, #root, .outer {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.outer {
  
  justify-content: center;
  flex-direction: column;
  text-align: left;

  margin-top: 100px
}



.outer .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.outer h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}